import {ImageIcon} from "../../atoms/icon";
import classNames from "classnames";

export function ParagraphDetailCard({paragraph, ...props}) {

  return (
    <div className={classNames(
      "paragraph--detail-card bg-white p-12 text-center h-full rounded-md flex flex-col justify-between",
      {"drop-shadow-sm": !paragraph?.field_detail_card_hide_shadow},
    )}>
      <div>
        {paragraph?.field_detail_card_icon?.name && (
          <div className="mb-2 h-10">
            <ImageIcon type={paragraph.field_detail_card_icon.name} color={"primary"} height={50} width={50} alt={paragraph?.field_detail_card_title} className={"m-auto object-contain max-h-full"}/>
          </div>
        )}
        <div className="font-bold text-primary text-2xl pb-3">
          {paragraph?.field_detail_card_title}
        </div>
      </div>
      {paragraph?.field_detail_card_description && (
        <p className="leading-6">{paragraph.field_detail_card_description}</p>
      )}
    </div>
  )
}
